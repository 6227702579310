import * as React from "react";
import SkeletonLoader from "tiny-skeleton-loader-react/dist";


class LoadingRow extends React.Component {

    render() {
        return (
            <tr>
                <td className="table-user">
                    <SkeletonLoader />
                </td>
                <td>
                    <SkeletonLoader />
                </td>
                <td>
                    <SkeletonLoader />
                </td>
                <td className="table-actions">
                    <SkeletonLoader />
                </td>
            </tr>
                ); }
}

export {LoadingRow};
