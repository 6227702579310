class IngridientService {

    private static ENDPOINT = 'https://dev-api.ernaehrungstool.de';


    public static async listIngridients(scope: string, accessToken?: string) {
        console.log(scope + " / " + accessToken);

        return [];
    }



}

export {IngridientService};
