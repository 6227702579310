import * as React from "react";


import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Input,
    Form,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader";


class IngridientsEditProps {
    ingridient_id: string | undefined;
    onClose: Function | undefined;
}

class IngridientsEditState {
    loading: boolean = true;
    storing: boolean = true;
}

class IngridientsEdit extends React.Component<IngridientsEditProps, IngridientsEditState> {

    constructor(props: Readonly<IngridientsEditProps>) {
        super(props);

        this.onSave = this.onSave.bind(this);
    }


    onClose(e) {
        e.preventDefault();

        if (this.props.onClose)
            this.props.onClose();
    }

    onSave(e) {
        e.preventDefault();

        this.setState({
            storing: true
        });

    }


    render() {
        const ingridientId = this.props.ingridient_id;
        const mode = ingridientId === "new" ? "create" : "edit";
        const storing = this.state?.storing;

        return (
            <>
                <SimpleHeader name="Zutaten"/>
                <Container className="mt--6" fluid>
                    <Form>
                        <Row>
                            <div className="col-2"></div>
                            <div className="col-8">

                                <Card>
                                    <CardHeader className="border-0">
                                        <Row>
                                            <Col xs="6">
                                                <h3 className="mb-0">{mode === "create" ? 'Neue Zutat anlegen' : 'Zutat bearbeiten'}</h3>
                                            </Col>
                                            <Col className="text-right" xs="6">
                                                <Button
                                                    className="btn-neutral btn-round btn-icon"
                                                    color="default"
                                                    href="#pablo"
                                                    id="close-button-24124231"
                                                    onClick={e => this.onClose(e)}
                                                    size="sm"
                                                >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-times"/>
                    </span>

                                                </Button>
                                                <UncontrolledTooltip delay={0} target="close-button-24124231">
                                                    Abbrechen und zurück zur Liste
                                                </UncontrolledTooltip>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>

                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="nameInput"
                                            >
                                                Name
                                            </label>
                                            <Input
                                                id="nameInput"
                                                placeholder="Mehl"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="categorySelect"
                                            >
                                                Kategorie
                                            </label>
                                            <Input id="categorySelect" type="select">
                                                <option value="ALL">Alle</option>
                                            </Input>
                                        </FormGroup>

                                    </CardBody>

                                </Card>

                                <Card>
                                    <CardHeader>
                                        <h3>Nährwertangaben pro 100 g</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="kcalInput"
                                                    >
                                                        kCal
                                                    </label>
                                                    <Input
                                                        id="kcalInput"
                                                        placeholder="100"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="carbInput"
                                                    >
                                                        Kohlenhydrate (g)
                                                    </label>
                                                    <Input
                                                        id="carbInput"
                                                        placeholder="100"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="fatInput"
                                                    >
                                                        Fett (g)
                                                    </label>
                                                    <Input
                                                        id="fatInput"
                                                        placeholder="100"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="proteinInput"
                                                    >
                                                        Eiweiß (g)
                                                    </label>
                                                    <Input
                                                        id="proteinInput"
                                                        placeholder="100"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardHeader>
                                        <h3>Umrechnung</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs="4">
                                                100 g entsprechen:
                                            </Col>
                                            <Col xs="4">
                                                <FormGroup>

                                                    <Input
                                                        id="carbInput"
                                                        placeholder="100"
                                                        defaultValue="100"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col xs="4">
                                                <FormGroup>
                                                    <Input id="quantityUnitSelect" type="select" defaultValue="g">
                                                        <optgroup label="Gewicht">
                                                            <option>mg</option>
                                                            <option>g</option>
                                                            <option>kg</option>
                                                        </optgroup>
                                                        <optgroup label="Volumen">
                                                            <option>ml</option>
                                                            <option>l</option>
                                                        </optgroup>
                                                        <optgroup label="Sonstiges">
                                                            <option value="count">Stück</option>
                                                        </optgroup>

                                                    </Input>
                                                </FormGroup>
                                            </Col>


                                        </Row>

                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardHeader>
                                        <h3>Meta Daten</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="sourceInput"
                                            >
                                                Quelle
                                            </label>
                                            <Input
                                                id="sourceInput"
                                                placeholder="https://..."
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="notesTextarea"
                                            >
                                                Notizen
                                            </label>
                                            <Input
                                                id="notesTextarea"
                                                rows="3"
                                                type="textarea"
                                            />
                                        </FormGroup>
                                    </CardBody>


                                    <CardFooter className="py-4 text-right">
                                        {storing ? 'Speichern' : 'Bearbeiten'}
                                        <nav aria-label="...">
                                            <Button
                                                className="btn-round btn-icon"
                                                color="primary"
                                                href="#pablo"
                                                id="close-button-24124231"
                                                onClick={e => this.onSave(e)}
                                                size="sm"
                                            >
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-save"/>
                                            </span>
                                                <span className="btn-inner--text">Speichern</span>
                                            </Button>
                                            <Button
                                                className="btn-neutral btn-round btn-icon"
                                                color="default"
                                                href="#pablo"
                                                id="close-button-24124231"
                                                onClick={e => this.onClose(e)}
                                                size="sm"
                                            >
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-times"/>
                                            </span>
                                                <span className="btn-inner--text">Abbrechen</span>
                                            </Button>
                                        </nav>
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </Form>
                </Container>
            </>
        );
    }


}

export {IngridientsEdit};
