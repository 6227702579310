import * as React from "react";


class MessageRowProps {
    message?: string;
}

class MessageRowState {
}

class MessageRow extends React.Component<MessageRowProps, MessageRowState> {


    render() {
        const message = this.props.message || "&nbsp;";


        return (
            <tr>
                <td className="table-user text-center" colSpan={4} dangerouslySetInnerHTML={{__html: message}}>
                </td>
            </tr>
        );
    }
}

export {MessageRow};
