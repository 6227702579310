import React from "react";
import {withOktaAuth, AuthService} from '@okta/okta-react';
// core components


import { Card, CardHeader, CardBody, Container, Row, Col, CardFooter, Button } from "reactstrap";
import AppIcon from "../components/overview/AppIcon";

class OverviewProps {
    authService: AuthService
}

class OverviewState {

}

export default withOktaAuth(class Overview extends React.Component<OverviewProps, OverviewState> {


    constructor(props, context) {
        super(props, context);

        this.state = {

        };

        this.logout = this.logout.bind(this);
    }

    logout() {
        this.props.authService.logout();
    }


    render() {
        return (
            <>
                <div className="main-content wmc-background overview-layout" ref="mainContent">

                    <Card>
                        <CardHeader>
                            WASP Management Console
                        </CardHeader>
                        <CardBody>
                            <Container>
                                <Row>
                                    <Col>
                                       <AppIcon label="Sites" path="/sites/" icon="fas fa-window-restore" color="rgb(44, 124, 176)"/>
                                    </Col>
                                    <Col>
                                        <AppIcon label="System" path="/system/" icon="fas fa-cogs" color="#73ab18"/>
                                    </Col>
                                    <Col>
                                        <AppIcon label="Product DB" path="/pdb/" icon="fas fa-database" color="#f48120"/>
                                    </Col>
                                </Row>
                                {/*

                                <Row>
                                    <Col>
                                        <AppIcon label="Sites" path="/sites/" icon="fas fa-window-restore" color="#cb3837"/>
                                    </Col>
                                    <Col>
                                        <AppIcon label="System" path="/system/" icon="fas fa-window-restore" color="#2c5fdb"/>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                                */}
                            </Container>
                        </CardBody>
                        <CardFooter className="text-right">
                            <Button
                                className="btn-round btn-icon text-nowrap"
                                color="neutral"
                                id="login-button-24124231"
                                size="sm"
                                onClick={() => this.logout()}
                            >

                                <i className="fas fa-sign-in-alt"></i>
                                <span className="btn-inner--text">Logout</span>
                            </Button>
                        </CardFooter>
                    </Card>

                </div>
            </>
        );
    }
});

