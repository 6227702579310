import React from "react";
import styles from './AppIcon.module.scss';

class AppIconProps {
    label?: string;
    path?: string;
    icon?: string;
    color?: string;

}

class AppIconState {

}

export default class AppIcon extends React.Component<AppIconProps, AppIconState> {


    constructor(props: Readonly<AppIconProps>) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        window.location.href = String(this.props.path);
    }

    render() {
        return (
            <div className={styles.AppIcon} onClick={() => this.onClick()}>
                <div className={styles.IconWrapper} style={{color : this.props.color}}>
                    <i className={this.props.icon}></i>
                </div>
                <div className={styles.IconLabel}>
                    {this.props.label}
                </div>
            </div>
        );

    }

}

