/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";

import {LoginCallback, Security, SecureRoute} from '@okta/okta-react';

import AdminLayout from "layouts/Admin.js";
import AppLayout from "layouts/App.js";
import AuthLayout from "layouts/Auth.js";
import Overview from "./layouts/Overview";

const CALLBACK_PATH = '/login/callback';

const oktaConfig = {
    clientId: '0oa166j7crvqsRSlX4x7',
    issuer: 'https://login.schab.de',
    redirectUri: document.location.origin + '/login/callback',
    postLogoutRedirectUri: 'https://login.schab.de/',
    scopes: ['openid', 'profile', 'email', 'groups'],
    pkce: true
};



ReactDOM.render(
  <BrowserRouter>
      <Security {...oktaConfig}>
          <Switch>
              <Route path={CALLBACK_PATH} component={LoginCallback} />
              <SecureRoute path="/app" render={props => <AppLayout {...props} />} />
              <SecureRoute path="/admin" render={props => <AdminLayout {...props} />} />
              <SecureRoute path="/auth" render={props => <AuthLayout {...props} />} />
              <SecureRoute path="/" render={props => <Overview {...props} />} />
              <Redirect from="*" to="/" />
          </Switch>
      </Security>
  </BrowserRouter>,
  document.getElementById("root")
);
