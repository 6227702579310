/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
// core components
import IngridientsOverview from "./IngridientsOverview";
import {IngridientsEdit} from "./IngridientsEdit";

class IngridientsProps {

}

class IngridientsState {
    mode: string = "overview";
    ingridient_id: string | undefined;
}


class Ingridients extends React.Component<IngridientsProps, IngridientsState> {

    constructor(props: IngridientsProps, context: any) {
        super(props, context);

        this.state = {
            mode: "overview",
            ingridient_id: undefined
        };
    }

    addNew() {
        console.log("Add New Ingridient!");

        this.setState({
            mode: "edit",
            ingridient_id: "new"
        });

    }

    reset() {
        this.setState({
            mode: "overview",
            ingridient_id: undefined
        })
    }


    render() {
        const mode = this.state?.mode;
        const ingridientId = this.state?.ingridient_id;

        console.log(mode + " / " + ingridientId);

        switch (mode) {
            case "overview":
                return (<IngridientsOverview onAddNew={() => this.addNew()}/>);

            case "edit":
                return (<IngridientsEdit ingridient_id={ingridientId} onClose={() => this.reset()}/>);
        }

    }


}


export default Ingridients;
