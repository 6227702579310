/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
// reactstrap components
import {Col, Container, Row, Button} from "reactstrap";

class IngridientHeaderProps {
    scope?: string;
    onScopeSwitch?: Function;
    publicCount?: number;
    privateCount?: number;
}

class IngridientHeaderState {
    scope?: string;
}


class IngridientsHeader extends React.Component<IngridientHeaderProps, IngridientHeaderState> {


    constructor(props: Readonly<IngridientHeaderProps>) {
        super(props);

        this.state = {
            scope: props.scope
        };
        this.onScopeSwitch = this.onScopeSwitch.bind(this);
    }

    onScopeSwitch(newScope: string, e: Event) {
        e.preventDefault();

        this.setState({
            scope : newScope
        });

        if (this.props.onScopeSwitch)
            this.props.onScopeSwitch(newScope);
    }

    render() {
        const scope = this.state.scope;
        let publicCount = "0";
        let privateCount = "0";

        if (this.props.privateCount)
            privateCount = this.props.privateCount.toLocaleString();

        if (this.props.publicCount)
            publicCount = this.props.publicCount.toLocaleString();

        return (
            <>
                <div className="header header-dark pb-6 content__title content__title--calendar">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center py-4">
                                <Col lg="6" xs="7">
                                    <Button
                                        className="btn-round btn-icon"
                                        color={scope === "private" ? 'primary' : 'neutral'}
                                        href="#pablo"
                                        id="close-button-24124231"
                                        onClick={e => this.onScopeSwitch('private', e)}
                                        size="sm"
                                    >
                                        <span className="btn-inner--text">Meine Zutaten ({privateCount})</span>
                                    </Button>
                                    <Button
                                        className="btn-round btn-icon"
                                        color={scope === "public" ? 'primary' : 'neutral'}
                                        href="#pablo"
                                        id="close-button-24124231"
                                        onClick={e => this.onScopeSwitch('public', e)}
                                        size="sm"
                                    >

                                        <span className="btn-inner--text">Öffentliche Zutaten ({publicCount})</span>
                                    </Button>
                                </Col>
                                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">

                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}


export default IngridientsHeader;
