import * as React from "react";
import {LoadingRow} from "./LoadingRow";
import {withOktaAuth} from '@okta/okta-react';

import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip
} from "reactstrap";
import IngridientsHeader from "./IngridientsHeader";
import {Ingridient} from "@wusoma/ltt-lib-core/lib/entities/ingridient";
import {MessageRow} from "./MessageRow";
import {IngridientService} from "../../../services/ingridient-service";


class IngridientsOverviewProps {
    onAddNew: Function | undefined;
    authState: any;
    authService: any;

    pageSize: number = 25;
}

class IngridientsOverviewState {
    scope?: string;

    privateIngridients?: IngridientsLoadingState;
    publicIngridients?: IngridientsLoadingState;

    currentPage: number = 1;

    authState?: any;
}

class IngridientsLoadingState {
    status?: string;
    ingridients?: Ingridient[];
    count?: number;

}

export default withOktaAuth(class IngridientsOverview extends React.Component<IngridientsOverviewProps, IngridientsOverviewState> {

    constructor(props: Readonly<IngridientsOverviewProps>) {
        super(props);

        this.state = {
            scope: "private",
            privateIngridients: {
                status: "NEW",
                ingridients: [],
                count: 0
            },
            publicIngridients: {
                status: "NEW",
                ingridients: [],
                count: 0
            },
            currentPage: 1,
            authState: this.props.authState
        };

        this.onAddNew = this.onAddNew.bind(this);
        this.onScopeSwitch = this.onScopeSwitch.bind(this);
        this.updateAuthState = this.updateAuthState.bind(this);
        this.props.authService.on('authStateChange', this.updateAuthState);

    }

    updateAuthState() {
        // console.log("Update AuthState");
        this.setState(Object.assign(this.state, {authState: this.props.authService.getAuthState()}));
        this.loadScopeIngridients("private");
    }

    componentDidMount(): void {
        console.log("componentDidMount IngridientsOverview");

        this.loadIngridients();
    }

    loadIngridients() {
        this.loadScopeIngridients("private");
        this.loadScopeIngridients("public");
    }

    async loadScopeIngridients(scope: string) {
        let loadingState: IngridientsLoadingState = {};
        if (scope === "private" && !this.state.authState.isAuthenticated) {
            loadingState = {
                status: "LOADED",
                ingridients: [],
                count: 0
            }
        } else {
            const ingridients = await IngridientService.listIngridients(scope, this.state.authState.accessToken)
            loadingState = {
                status: "LOADED",
                ingridients: ingridients,
                count: ingridients.length
            };
        }

        // console.log("New Loading State for " + scope, loadingState);

        const newState = {};
        newState[scope + "Ingridients"] = loadingState;

        this.setState(Object.assign(this.state, newState));
    }


    onAddNew() {

        if (this.state.authState.isAuthenticated) {
            if (this.props.onAddNew)
                this.props.onAddNew();
        } else {
            this.props.authService.login();
        }
    }

    onScopeSwitch(newScope: string) {
        const newState = Object.assign(this.state, {scope: newScope});
        this.setState(newState);

    }


    render() {
        const scope = this.state.scope;
        let newButtonClass = "btn-neutral btn-round btn-icon";
        if (scope === "public")
            newButtonClass = "d-none";

        const privateCount = this.state.privateIngridients?.count;
        const publicCount = this.state.publicIngridients?.count;

        const viewingIngridientsState = scope === "private" ? this.state.privateIngridients : this.state.publicIngridients;

        let rows: any[] = [
            (<LoadingRow key={1}/>),
            (<LoadingRow key={2}/>),
            (<LoadingRow key={3}/>),
            (<LoadingRow key={4}/>),
            (<LoadingRow key={5}/>),
        ];

        if (!this.state.authState.isPending && !this.state.authState.isAuthenticated && this.state.scope === "private") {
            rows = [
                (<MessageRow key={1} message="&nbsp;"/>),
                (<MessageRow key={2}
                             message="Sie müssen eingeloggt sein um Ihre <b>persönliche Zutaten</b> sehen zu können."/>),
                (<MessageRow key={3}
                             message="Oder wählen Sie oben <b>Öffentliche Zutaten</b> um diese angezeigt zu bekommen."/>),
                (<MessageRow key={4} message="&nbsp;"/>),
                (<MessageRow key={5} message="&nbsp;"/>),
            ];
        }

        // console.log(viewingIngridientsState);
        // console.log(this.state.authState);

        if (scope === "public" || this.state.authState.isAuthenticated) {

            if (viewingIngridientsState?.status === "LOADED") {
                if (viewingIngridientsState.count === 0) {
                    rows = [
                        (<MessageRow key={1}/>),
                        (<MessageRow key={2}/>),
                        (<MessageRow key={3} message="Keine Zutaten gefunden"/>),
                        (<MessageRow key={4}/>),
                        (<MessageRow key={5}/>),
                    ];
                } else {


                }
            }
        }


        return (
            <>
                <IngridientsHeader scope={scope} onScopeSwitch={this.onScopeSwitch} publicCount={publicCount}
                                   privateCount={privateCount}/>
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col xs="6">
                                            <h3 className="mb-0">Liste der Zutaten</h3>
                                        </Col>
                                        <Col className="text-right" xs="6">
                                            <Button
                                                className={newButtonClass}
                                                disabled={scope === "public"}
                                                color="default"
                                                id="tooltip969372949"
                                                onClick={() => this.onAddNew()}
                                                size="sm"
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                  <i className="fas fa-plus"/>
                                                </span>
                                                <span className="btn-inner--text">Neue Zutat</span>
                                            </Button>
                                            <UncontrolledTooltip delay={0} target="tooltip969372949">
                                                Eine neue Zutat hinzufügen
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th>Name</th>
                                        <th>Kategorie</th>
                                        <th>Nährwert</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {rows}
                                    </tbody>
                                </Table>

                                <CardFooter className="py-4">
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            <PaginationItem className="disabled">
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={e => e.preventDefault()}
                                                    tabIndex="-1"
                                                >
                                                    <i className="fas fa-angle-left"/>
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem className="active">
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    1
                                                </PaginationLink>
                                            </PaginationItem>

                                            <PaginationItem>
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    <i className="fas fa-angle-right"/>
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }


});
