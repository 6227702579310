import React from "react";
import {withOktaAuth} from '@okta/okta-react';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Media,
    Nav,
    Row,
    UncontrolledDropdown
} from "reactstrap";

const getGravatarUrl = require('gravatar-url');

class UserNavItemProps {
    authService: any;
    authState: any;
}

class UserNavItemState {
    userInfo: any;
    gravatarUrl?: string;
}


export default withOktaAuth(class UserNavItem extends React.Component<UserNavItemProps, UserNavItemState> {
    _isMounted = false;

    constructor(props, context) {
        super(props, context);

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.checkUser = this.checkUser.bind(this);

        this.state = {
            userInfo: null
        };
    }


    async checkUser() {
        if (this.props.authState.isAuthenticated && !this.state.userInfo) {
            const userInfo = await this.props.authService.getUser();
            if (this._isMounted) {
                const gravatarUrl = getGravatarUrl(userInfo.email, {size: 100});
                this.setState({
                    userInfo,
                    gravatarUrl
                });
            }
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        this.checkUser();
    }

    async componentDidUpdate() {
        this._isMounted = true;
        this.checkUser();
    }

    login() {
        this.props.authService.login();
    }

    logout() {
        this.props.authService.logout();
    }


    render() {
        if (this.props.authState.isPending) {
            return null;
        } else {

            if (this.props.authState.isAuthenticated) {
                if (this.state?.userInfo) {
                    const userInfo = this.state.userInfo;
                    const gravatarUrl = this.state.gravatarUrl;

                    return (
                        <Nav className="align-items-center ml-auto ml-md-0 clickable" navbar>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                            alt="..."
                            src={gravatarUrl}
                        />
                      </span>
                                        <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                            {userInfo.name}
                        </span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem className="noti-title" header tag="div">
                                        <h6 className="text-overflow m-0">Welcome!</h6>
                                    </DropdownItem>
                                    <DropdownItem
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="ni ni-single-02"/>
                                        <span>My profile</span>
                                    </DropdownItem>
                                    <DropdownItem
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="ni ni-settings-gear-65"/>
                                        <span>Settings</span>
                                    </DropdownItem>
                                    <DropdownItem
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="ni ni-calendar-grid-58"/>
                                        <span>Activity</span>
                                    </DropdownItem>
                                    <DropdownItem
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="ni ni-support-16"/>
                                        <span>Support</span>
                                    </DropdownItem>
                                    <DropdownItem divider/>
                                    <DropdownItem
                                        onClick={() => this.logout()}
                                    >
                                        <i className="ni ni-user-run"/>
                                        <span>Logout</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    );


                } else {
                    return null;
                }

            } else {


                return (
                    <Nav className="align-items-center ml-auto ml-md-0" navbar>
                        <Row className="align-items-center">
                            <Col>
                                <Button
                                    className="btn-round btn-icon text-nowrap"
                                    color="primary"
                                    id="register-button-24124231"
                                    size="sm"
                                    onClick={() => this.login()}
                                >
                                    <i className="fas fa-user-plus"></i>
                                    <span className="btn-inner--text">Registrieren</span>
                                </Button>

                            </Col>
                            <Col>
                                <Button
                                    className="btn-round btn-icon text-nowrap"
                                    color="neutral"
                                    id="login-button-24124231"
                                    size="sm"
                                    onClick={() => this.login()}
                                >

                                    <i className="fas fa-sign-in-alt"></i>
                                    <span className="btn-inner--text">Login</span>
                                </Button>
                            </Col>
                        </Row>
                    </Nav>
                );


            }


        }
    }

});
